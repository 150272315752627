@import url("https://fonts.googleapis.com/css?family=Josefin+Sans");

.App {
  text-align: center;
}

.My-name {
  font-family: "Josefin Sans";
  transition: all 0.3s;
}

.Last-name {
  position: relative;
  display: inline-block;
  padding: 0;
  transition: all 300ms;
  transform-origin: 50% 0;
  transform-style: preserve-3d;

  &:hover,
  &:focus {
    transform: rotateX(90deg);
  }

  &::before {
    position: absolute;
    top: 100%;
    left: 0;
    color: goldenrod;
    content: "prytulla";
    transform: rotateX(-90deg);
    transform-origin: 50% 0;
    text-align: center;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
